// node_modules
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
// Enums
import {
  LinkStatusEnum,
  ObjectTypeEnum,
  SavedDocumentTypeEnum,
  SortTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Components
import {
  ConnectedQueries,
  Editor,
  FileUploadProgressIndicator,
  HasAdvanced,
  LinkingModal,
  MaturityRadar,
  PageComments,
  ReferencedBy,
  RequirementsTable,
  SavedDocuments,
} from "Components";
// Contexts
import {
  AuthContext,
  EditorContext,
  ElementVisibilityContext,
} from "Providers";
// Types
import {
  TIdNameTypeObjectType,
  TImageDTO,
  TOption,
  TSavedFileDTO,
} from "Types";
// Constants
import { EventConstants, FeatureToggleConstants, LinkingConstants } from "Constants";
// Controllers
import { SavedFileControllerSingleton } from "Controllers";
// Helpers
import { ToastHelperSingleton, UserHelperSingleton } from "Helpers";
// Interfaces
import { IObject, ISavedDocumentDTO } from "Interfaces";
// Styles
import entityLikeCardStyles from "Styles/entityLikeCard.module.scss";
// Custom hooks
import { useCreateEntity } from "Hooks";

type TObjectDetailsProps<T extends IObject> = {
  children?: React.ReactNode;
  object?: T;
  setObject: Dispatch<SetStateAction<T | undefined>>;
  objectType: ObjectTypeEnum;
  type: string;
  onImageInsertedAsync: (
    image: File,
    caption?: string
  ) => Promise<TImageDTO | undefined>;
  onSourceChangeAsync: (
    newValue: string,
    forceUpdate: boolean
  ) => Promise<void>;
  refreshDocumentsAsync: (
    fromDate: Date | undefined,
    selectedFilterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[],
    sortType: SortTypeEnum,
    callback?: (newSavedDocuments: ISavedDocumentDTO[]) => void
  ) => Promise<void>;
  deleteSavedDocumentAsync?: (
    savedDocumentsToDelete: ISavedDocumentDTO[]
  ) => Promise<void>;
};

export function ObjectDetails<T extends IObject>({
  children,
  object,
  setObject,
  objectType,
  type,
  onImageInsertedAsync,
  onSourceChangeAsync,
  refreshDocumentsAsync,
  deleteSavedDocumentAsync,
}: TObjectDetailsProps<T>) {
  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();

  // Context
  const { auth } = useContext(AuthContext);
  const {
    isEditOn,
    isObjectLocked,
    setIsObjectLocked,
    setSavedDocumentsCount,
    isLinkingModalOpen,
    setIsLinkingModalOpen,
    linkToName,
    setLinkToName,
    editor,
    updateTOCItems,
    scrollingParentElement,
    setScrollingParentElement,
    setAskIgorModalOptions,
  } = useContext(EditorContext);
  const { canUserEdit, isRightSidebarCollapsed } = useContext(
    ElementVisibilityContext
  );

  // State
  const [fileUploadProgress, setFileUploadProgress] =
    useState<number | undefined>(undefined);
  const [isScrollingAreaPositionTop, setIsScrollingAreaPositionTop] =
    useState<boolean>(true);
  const [openDocumentModal, setOpenDocumentModal] = useState<{ url: string, id?: string} | undefined>(undefined);

  // Refs
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const { onCreateEntityAsyncCallback } = useCreateEntity({});

  useEffect(() => {
    // if the user cannot edit then do nothing
    if (canUserEdit || isObjectLocked) return;

    // If the linking parameter is enabled then open the linking modal
    // and remove the linking parameter
    if (searchParams.has("isLinking")) {
      setIsLinkingModalOpen(true);
      setSearchParams({});
    }
  }, [
    isObjectLocked,
    canUserEdit,
    searchParams,
    setIsLinkingModalOpen,
    setSearchParams,
  ]);

  useEffect(() => {
    setIsObjectLocked(object?.isLocked);
    setSavedDocumentsCount(object?.savedDocuments.length ?? 0);
  }, [
    object?.isLocked,
    object?.savedDocuments,
    setIsObjectLocked,
    setSavedDocumentsCount,
  ]);

  const uploadFileAsync = useCallback(
    async (file: File): Promise<TSavedFileDTO | undefined> => {
      if (!canUserEdit || isObjectLocked || !object) return undefined;

      abortControllerRef.current = new AbortController();

      const uploadedFile: TSavedFileDTO | null | undefined =
        await SavedFileControllerSingleton.createSavedFileUsingForm(
          file,
          file.name,
          object.id,
          objectType,
          setFileUploadProgress,
          abortControllerRef.current.signal
        );
      if (!uploadedFile) {
        if (uploadedFile === null) {
          return undefined;
        }

        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not attach file."
        );
        return undefined;
      }

      setObject({
        ...object,
        linkedFiles: [...object.linkedFiles, uploadedFile],
      });

      return uploadedFile;
    },
    [isObjectLocked, canUserEdit, object, objectType, setObject]
  );

  const onLinkingModalClose = useCallback(() => {
    setLinkToName(undefined);
  }, [setLinkToName]);

  const excludedSearchTypes = useMemo((): ObjectTypeEnum[] => {
    // if link to name is defined, exclude the study type
    // (we could have open linking modal through save as entity menu option)
    // and in this case we want to exclude the study type
    if (linkToName) {
      return [ObjectTypeEnum.Study];
    } else {
      return [];
    }
  }, [linkToName]);

  const onSaveClickCallback = useCallback(
    (selectedLinkToObject: TIdNameTypeObjectType): void => {
      if (
        !linkToName ||
        selectedLinkToObject.objectType !== ObjectTypeEnum.Entity ||
        !editor
      ) {
        return;
      }

      onCreateEntityAsyncCallback(selectedLinkToObject, editor);
    },
    [editor, linkToName, onCreateEntityAsyncCallback]
  );


  // open document modal of the id or matched URL
  const onReferenceClick = useCallback(async (event: Event) => {
    const url: string = (event as CustomEvent).detail.url;
    const id: string = (event as CustomEvent).detail.id;
    if (object?.savedDocuments.find((doc) => doc.url === url)) {
      setOpenDocumentModal({url, id});
    } else {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  }, [object?.savedDocuments]);

  useEffect(() => {
    window.addEventListener(EventConstants.INLINE_REFERENCE_EVENT, onReferenceClick);

    return () => {
      window.removeEventListener(EventConstants.INLINE_REFERENCE_EVENT, onReferenceClick);
    };
  }, [onReferenceClick]);

  // if object is undefined then return an empty div
  if (!object) return <div></div>;

  const scrollEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!scrollingParentElement) return;
    updateTOCItems();
    const target = e.target as HTMLDivElement;
    if (target.scrollTop > 0) {
      if (isScrollingAreaPositionTop) {
        setIsScrollingAreaPositionTop(false);
      }
    } else {
      setIsScrollingAreaPositionTop(true);
    }
  };

  const onCancelUpload = () => {
    // abort
    abortControllerRef.current.abort();
    setFileUploadProgress(undefined);
  };

  return (
    <div
      ref={setScrollingParentElement}
      className={`${entityLikeCardStyles.entityLikeCard} ${
        isRightSidebarCollapsed
          ? "rightSidebarCollapsed"
          : "rightSidebarUncollapsed"
      }`}
      onScroll={scrollEvent}
    >
      <div
        className={`${entityLikeCardStyles.isScrollingElement} ${
          isScrollingAreaPositionTop ? "" : entityLikeCardStyles.isScrolling
        }`}
      ></div>
      {children}
      {!UserHelperSingleton.isSharingRestrictedToObject(auth) && (
        <ReferencedBy referencedBy={object.referencedBy} />
      )}
      <div className={entityLikeCardStyles.entityLikeCardContentContainer}>
        <Editor
          onImageInsertedAsync={onImageInsertedAsync}
          onContentChangeAsync={onSourceChangeAsync}
          uploadFileAsync={uploadFileAsync}
          object={object}
          objectType={objectType}
          type={type}
          content={object.description}
        />
        {FeatureToggleConstants.RequirementsTable && (
          <div
            className={
              entityLikeCardStyles.entityLikeCardConnectedDocumentsContainer
            }
          >
            <RequirementsTable
              shouldShowEditingOptions={isEditOn}
              objectType={objectType}
              objectId={object.id}
            />
          </div>
        )}
        {FeatureToggleConstants.MaturityRadar && (
          <div>
            <MaturityRadar
              objectId={object.id}
              objectType={objectType}
              shouldShowEditingOptions={isEditOn}
            />
          </div>
        )}
        <div
          className={
            entityLikeCardStyles.entityLikeCardConnectedDocumentsContainer
          }
        >
          <SavedDocuments
            linkedToObjectId={object.id}
            header={"Linked documents"}
            documents={object.savedDocuments}
            doUseSavedFilters={false}
            totalDocumentsCount={object.totalDocumentsCount}
            refreshDocumentsAsync={refreshDocumentsAsync}
            deleteSavedDocumentAsync={
              !canUserEdit || isObjectLocked
                ? undefined
                : deleteSavedDocumentAsync
            }
            deleteIsUnlink={true}
            doHideIfNoDocumentsLinked={true}
            isEditable={canUserEdit && !isObjectLocked}
            isObjectDetails={true}
            setAskIgorModalOptions={setAskIgorModalOptions}
            openDocumentModal={openDocumentModal}
          />
        </div>
        <HasAdvanced>
          <div
            className={
              entityLikeCardStyles.entityLikeCardConnectedQueriesContainer
            }
          >
            <ConnectedQueries
              objectId={object.id}
              objectName={object.title}
              objectType={objectType}
            />
          </div>
        </HasAdvanced>
        <div
          className={entityLikeCardStyles.entityLikeCardPageCommentsContainer}
        >
          <PageComments
            currentUsername={auth.userEmail}
            objectType={objectType}
            objectId={object.id}
            header="Page comments"
          />
        </div>
      </div>
      <LinkingModal
        linkToName={linkToName}
        isOpen={isLinkingModalOpen}
        setIsOpen={setIsLinkingModalOpen}
        selectedObjects={[
          { id: object.id, name: object.title, type, objectType },
        ]}
        defaultLinkType={LinkingConstants.CHILD_LINK_TYPE}
        excludedSearchTypes={excludedSearchTypes}
        onClose={onLinkingModalClose}
        onSaveClickCallback={onSaveClickCallback}
      />
      <FileUploadProgressIndicator
        onCancelClick={onCancelUpload}
        fileUploadProgress={fileUploadProgress}
      />
    </div>
  );
}
