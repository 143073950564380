import { useRef, useEffect } from "react";

export function useDebounce<T>(callback: (args: T) => void, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const debouncedFunction = (args: T) => {

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(args);
    }, delay);
  };

  return debouncedFunction;
}

export default useDebounce;